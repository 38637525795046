import React from "react";
import logo from "./logo.svg";
import ln from "./ln.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          <code>
            Hello... My name is Federico Martinez. I'm a software developer from
            Uruguay. And yes... This React Templated site is my page. Some day
            I'll update this page with personal projects.. in the future
          </code>
          <br />
          <code>
            Anyway, you can reach me via Linkedin if you want! Let's talk!
          </code>
          <br />
          <a href="https://www.linkedin.com/in/federicomartinmartinez/">
            <img style={{ height: 50 }} src={ln} alt="Linkedin" />
          </a>
        </p>
      </header>
    </div>
  );
}

export default App;
